import React, { useState } from 'react';
import '../New.css'; // Import the CSS file for styling
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';

const ContactForm = () => {

  const [message, setMessage] = useState({
    name: null,
    email: null,
    phone: null,
    text: null
  });

  const [sending, setSending] = useState(false);

  const baseURL = 'https://goldenconnect.devstesting.com/api';
  const navigate = useNavigate();

  function isValidEmail(email) {
    // Regular expression pattern for validating email addresses
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    
    // Test the email string against the pattern
    return emailPattern.test(email);
}

  const validateInput = async() => {
    if(message.name === null) {
      alert("Please tell us your name")
      return false;
    }
    if(isValidEmail(message.email) === false) {
      alert("Please give us a valid email address");
      return false;
    } 
    if(message.phone.length < 10) {
      alert("Your phone number should be at least 10 digits");
      return false
    }
    if(message.text === null) {
      alert("Please write something. What is your message?");
      return false
    }
    return true;
  }

  const submitMessage = async() => {
    if((await validateInput()).valueOf() == false) {
      return;
    } else {
      setSending("Sending...");
      await axios.post(baseURL + '/message', message)
      .then(async({data}) => {
        alert("Message Sent");
        setSending(false);
        navigate("/");
      }).catch((error) => {
        setSending(false);
        alert("Could not send message. Please try again later");
        console.log(error);
        return;
      });
    }
    
  }

  return (
    <div className="formcontainer">
      {sending !== false &&
        <div className='w-screen h-screen flex flex-col justify-center items-center fixed top-0 left-0 z-20 bg-black bg-opacity-40'>
          <label className='font-inia font-bold text-3xl text-white'>{sending}</label>
        </div>
      }
      <div className="card">
        <h2>Send Us A Message</h2>
        <div>
          <div className="form-group">
            <label htmlFor="name">Name:</label>
            <input type="text" onChange={(e) => setMessage({...message, name: e.target.value})} id="name" name="name" className='bg-white rounded text-black' required />
          </div>
          <div className="form-group">
            <label htmlFor="email">Email:</label>
            <input type="email" id="email" onChange={(e) => setMessage({...message, email: e.target.value})} name="email" className='text-black' required />
          </div>
          <div className="form-group">
            <label htmlFor="phone">Phone Number:</label>
            <input onChange={(e) => setMessage({...message, phone: e.target.value})} type="tel" id="phone" name="phone" className='text-black' required />
          </div>
          <div className="form-group">
            <label htmlFor="message">Message:</label>
            <textarea id="message" onChange={(e) => setMessage({...message, text: e.target.value})} name="message" className='text-black resize-none' rows="4" required />
          </div>
          <button onClick={() => submitMessage()} type="submit" className='w-full ml-0'>Submit</button>
        </div>
      </div>
      <div className="card">
        <h2>   <i  class="fa fa-envelope" aria-hidden="true"></i>  Email</h2>
        <div className='mb-8'>
          <Link to={`mailto:info@goldenconnectlimited.com`}>info@goldenconnectlimited.com</Link>
        </div>
        <h2>  <i  class="fa fa-phone" aria-hidden="true"></i>  Phone</h2>
        <div>
          <label>Nigeria: </label>
          <Link to={`tel:${+2347072680329}`}>+234-7072-680329</Link>
        </div>
        <div>
          <label>UK: </label>
          <Link to={`tel:${+447568734888}`}>+44-758-664-5941</Link>
        </div>
        <div>
          <label>Canada: </label>
          <Link to={`tel:${+180366486688}`}>+1 (416) 907-0794</Link>
        </div>
        <br />
        <h2>  <i  class="fab fa-whatsapp" aria-hidden="true"></i>  WhatsApp</h2>
        <div>
          <label>Nigeria: </label>
          <Link to={`https://wa.me/${+2347072680329}`}>+234-7072-680-329</Link>
        </div>
        <div>
          <label>UK: </label>
          <Link to={`https://wa.me/${+447568734888}`}>+44-758-664-5941</Link>
        </div>
        <div>
          <label>Canada: </label>
          <Link to={`https://wa.me/${+180366486688}`}>+1-(803)-664-8 6688</Link>
        </div>
      </div>
    </div>
  );
};

export default ContactForm;
